.footerText{
    color: rgba(0, 0, 0, .45);
    font-size: 13px;
    line-height: 17px;
    padding: 0 7px 8px 9px;
}

.whatsappButton{
    border: 1px solid #dadde1;
    color: #00a5f4;
    font-size: 14px;
    height: 44px;
    line-height: 20px;
    min-width: 240px;
    white-space: pre-wrap;
    cursor: pointer;
}
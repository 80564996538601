
.storeIconPadding{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border:1px solid #0D75BA;
    border-radius: 6px;
}
.storeIconPadding1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border:1px solid #0D75BA;
    border-top-left-radius: 7px; /* Adjust as needed */
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 7px;
}

@media screen and (max-width: 600px) {
    .storeIconPadding {
        width: 50px;
    }
}

.chatSelect{
    margin:20px 0px;
    display: flex;
    justify-content: flex-end;
}

.chatFont{
    font-family: Inter;
    font-weight: 500;
}
.chatFontCenter{
    font-family: Inter;
    font-weight: 500;
    text-align: center;
}

.chatFontHeebo{
    font-family: Heebo;
    font-weight: 500;
}
.chatFontHeebo400{
    font-family: Heebo;
    font-weight: 400;
    color:black
}
.chatFontHeebo400White{
    font-family: Heebo;
    font-weight: 400;
    color:white;
}
.chatFontHeebo400Red{
    font-family: Heebo;
    font-weight: 400;
    color:red;
}
.chatFontHeebo400Gray{
    font-family: Heebo;
    font-weight: 400;
    color:#7C7B7B;
}
.chatFontHeebo400Color{
    font-family: Heebo;
    font-weight: 400;
    color: #8B8989;
}

.centerAlign{
    display: flex;
    align-items: center;
 
}

.messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position:relative;
  }
  
  .message {
   
    padding: 8px;
    border-radius: 5px;
    position: relative;
}

.centerdiv{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.custom-success-message .anticon-check-circle {
    color: #0D75BA !important;
}
  
  .chatBackground {
    background-color: #f5f0f0;
  }
  
  .Label{
   
        font-family: "Heebo";
        font-weight: 500; 
        color: #333333;
        margin-top: 12px;
        margin-bottom: 6px;
        text-align: left;
        line-height: 1.5

  }
/* .upload-container {
    border: 1px solid #007bff;
    padding: 16px;
    border-radius: 8px;
    max-width: 400px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .upload-header {
    margin-bottom: 16px;
  }
  
  .upload-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .upload-label {
    position: relative;
    cursor: pointer;
  }
  
  .upload-label input[type="radio"] {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
  }
  
  .upload-option {
    width: 60px;
    height: 60px;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .upload-option.image-option {
    background: url("/images/image.svg") no-repeat center center;
    background-size: contain;} Adjust as needed

  .upload-option.video-option::before {
    content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PHBhdGggZD0iTTAgMHYxOGgxOHYtMThoLTE4eiBNMTEgMTVsLTUgLTYgNSA2eiIgZmlsbD0iI0MwQzBDMCIvPjwvc3ZnPg==');
  }
  
  .upload-option.file-option::before {
    content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PHBhdGggZD0iTTEgMXYxNmgxNXYtaDctMnYtNWgtNnYtNWgtOHYtMnptNyAxMmgtNiBWMTB2NiBoNnYtNnoiIGZpbGw9IiNDMEMwQzAiLz48L3N2Zz4=');
  }
  
  .upload-input {
    display: flex;
    align-items: center;
  }
  
  #file {
    display: none;
  }
  
  .file-label {
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
  }
   */

   .upload-options {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .upload-label {
    position: relative;
    display: block;
    margin-bottom: 20px; /* Adjust as needed */
  }
  
  .upload-option {
    position: relative;
    display: inline-block;
  }
  
  .upload-option img {
    display: block;
  }
  
  .upload-option input[type="radio"] {
    position: absolute;
    top: 10%;
    left: 20%;
    transform: translate(-50%, -50%);
    z-index: 10; /* Ensure the radio button is on top */
    cursor: pointer; /* Change cursor to pointer for better UX */
  }
  
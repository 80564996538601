.main-div {
    background-color: #D9D9D9;
    padding: 30px;
    display: flex;
    /* justify-content: center; */
    margin-top: 57px;
    gap: 20px;
}

.sub-div {
    flex: 1;
    text-align: center;
    padding: 0px;
}
/* .testing-container {
    /* width: 100%;
    height: 100%; 
    background-color: rgb(117, 255, 255);
  } */
   
  .testing-container :focus {
    outline: none;
  }
   
  .testing-container .recharts-legend-wrapper .recharts-legend-item {
    margin: 0px 50px; /* Adjust the margin as needed */
  }
   
  .recharts-legend-wrapper {
    margin: -10px;
  }


   
   
  .custom-tooltip {
    position: relative;
    display: inline-flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 8px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: #000000;
    background-color: #f4f5f9;
    /* box-shadow: 0px 0px 10px 0px rgba(18, 18, 18, 0.25); */
    opacity: 1;
  }
   
  .custom-tooltip:before {
    content: "";
    position: absolute;
    top: 36px;
    left: 9px;
    border: solid 8px transparent;
    border-top-color: #f4f5f9;
    z-index: 1;
  }
  

  @media screen and (min-width: 1200px) and (max-width: 1500px) {
    .testing-container .recharts-legend-wrapper .recharts-legend-item  {
      margin: 0px 15px;
    }
    .recharts-legend-wrapper {
      margin: -5px;
    }
  }
  
  @media screen and (min-width: 900px) and (max-width: 1200px) {
    .testing-container .recharts-legend-wrapper .recharts-legend-item  {
      margin: 0px -130px;
    }
    /* .recharts-legend-wrapper {
      /* margin: 15px;
    } */ 
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    .testing-container .recharts-legend-wrapper .recharts-legend-item  {
      margin: 0px -130px;
    }
    /* .recharts-legend-wrapper {
      margin: 15px;
    } */
  }
  
  @media screen and (min-width: 0px) and (max-width: 600px) {
    .testing-container .recharts-legend-wrapper .recharts-legend-item  {
      margin: 0px -130px;
    }
    /* .recharts-legend-wrapper {
      margin: 15px;
    } */
  }
  